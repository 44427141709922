<template>
  <div>
    <b-card
        text-variant="center"
        class="card card-congratulations"
    >
      <!-- images -->
      <b-img
          :src="require('@/assets/images/elements/decore-left.png')"
          class="congratulations-img-left"
      />
      <b-img
          :src="require('@/assets/images/elements/decore-right.png')"
          class="congratulations-img-right"
      />
      <!--/ images -->

      <b-avatar
          variant="primary"
          size="70"
          class="shadow mb-2 circle"
      >
        <feather-icon
            size="28"
            icon="AwardIcon"
            class="award"
        />

      </b-avatar>
      <h1 class="mb-1 mt-50 text-white" v-if="creditAmount >= credits_in_use">
        Hey {{ userData.firstname }} {{ userData.fullName }}
      </h1>
      <h1 class="mb-1 mt-50 text-white" v-else>
        Hey {{ userData.fullName }},
      </h1>

      <b-card-text class="m-auto w-75">
        <h2 class="text-white" v-if="creditAmount >= credits_in_use">
          {{ $t('You have') }} <span class="text-bold">{{ creditAmount - credits_in_use }} </span>
          {{ $t('Credits available') }}</h2>
        <span v-else>{{ $t('You do not have enough Credits') }}</span>
      </b-card-text>
      <b-button v-on:click="distribute()"
                class="mt-md-2 mt-1 mr-md-1 mr-sm-0"
                variant="success"
                type="submit" size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      >
        {{ $t('Distribute credits equally') }}
      </b-button>

      <b-button v-on:click="resetCredits()"
                variant="danger"
                class="mt-2 ml-1" type="submit" size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      >
        {{ $t('Reset credit distribution') }}
      </b-button>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" :placeholder="$t('Search...')"/>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="employeesDistributeCreditsTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')"
        >

          <template #cell(available_credits)="data">
            {{ data.item.credits }}
          </template>

          <template #cell(credits)="data" v-for="(item, index) in items">
            <b-row>
              <div>

                <b-form-group>
                  <b-form-input
                      :key="item.id"
                      class="credit-input"
                      v-model="data.item.credit_count"
                      @blur="updateCreditAmount($event.target.value, data.item)"
                      :placeholder="$t('Credits')"
                      type="number"
                  />
                </b-form-group>
              </div>
            </b-row>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              {{ $t('Showing') }}
              <span v-if="items.length === 0"> {{ items.length }}</span>
              <span v-else-if="items.length > 0"> {{ perPage * (currentPage - 1) + (perPage ? 1 : 0) }}</span>
              {{ $t('to') }}
              <span v-if="items.length < perPage - 1"> {{ items.length }}</span>
              <span v-else-if="perPage * (currentPage) > items.length"> {{ items.length }}</span>
              <span v-else> {{ perPage * (currentPage - 1) + perPage }}</span>
              {{ $t('of') }} {{ items.length }} {{ $t('entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col md="6">
          <div class="d-flex mb-2 ml-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="sendCreditBalance"
            >
              {{ $t('Transfer Credits balances') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BImg,
  BCardText, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import {mapActions} from "vuex";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BImg,
    BCardText,
    vSelect,
    VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,

      credits: [],
      credit_amount: '',
      credits_employee: [],
      credits_in_use: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      tableColumns: [
        {
          key: 'name',
          sortable: true,
          label: this.$i18n.t('name'),
          formatter: this.formatName,
        },
        {
          key: 'email',
          sortable: true,
          label: this.$i18n.t('email'),
        },
        {
          key: 'created_at',
          sortable: true,
          label: this.$i18n.t('Date of creation'),
          formatter: (value) => {
            return moment(String(value))
                .format('DD.MM.YYYY')
          }
        },
        {
          key: 'role',
          sortable: true,
          label: this.$i18n.t('role'),
        },
        {
          key: 'available_credits',
          sortable: true,
          label: this.$i18n.t('Available Credits'),
        },
        {
          key: 'credits',
          label: this.$i18n.t('Distribute credits')
        }
      ],
      items: [],
      currentUser: JSON.parse(localStorage.getItem('userData')),
    }
  },

  mounted() {
    this.getItems()
    this.getCreditAmount()
  },

  computed: {
    creditAmount() {
      if (this.credit_amount >= 0) {
        return this.credit_amount
      } else {
        return 0
      }
    }
  },

  methods: {
    ...mapActions(['UPDATE_CREDITS']),

    getItems: function (url) {
      this.$http.get(`/client/users`)
          .then(response => {
            this.items = response.data.items
            this.totalRows = response.data.items.length
            this.loaded = true
            this.items.forEach(function (user) {
              user.credit_count = 0
            })
            this.items = this.items.filter(user => user.id != this.currentUser.id)
          })
          .catch(error => {
            this.loaded = true
          })
    },

    getCreditAmount: function (url) {
      this.$http.get(`/info/credits`)
          .then(response => {
            this.credit_amount = response.data.credit_amount
          })
          .catch(error => {
            this.loaded = true
            if (error.response.status === 404) {
            }
          })
    },

    distribute() {
      let credits = (Math.floor(this.credit_amount / this.items.length))
      let restCredits = this.credit_amount - credits * this.items.length

      let users = this.items

      for (let [index, user] of users.entries()) {
        this.items[index].credits = credits
      }

      this.items.forEach(function (user) {
        user.credit_count = credits
      })

      this.items.forEach(function (user) {
        if (restCredits == 0) return
        user.credit_count += 1
        restCredits--
      })

      this.$refs.employeesDistributeCreditsTable.refresh()

      this.distributedCredits = credits

      this.updateCreditsInUse()
    },

    resetCredits() {
      this.items.forEach(function (user) {
        user.credit_count = 0
      })
    },
    updateCreditsInUse() {
      let credits = 0

      this.items.forEach(function (user) {
        credits += user.credit_count * 1
      })

      this.credits_in_use = credits
    },

    updateCreditAmount(credits, item) {

      this.updateCreditsInUse()

      if (this.credits_in_use <= this.credit_amount) {
      } else {
        this.$swal({
          title: this.$i18n.t('You do not have enough credits, please recharge your Credits'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Buy more credits'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                this.$router.push({
                  name: 'buy-credits',
                })
              }
            })
      }
    },

    sendCreditBalance() {
      const isCreditsSet = this.items.some(user => {
        return Number(user.credit_count) > 0
      })
      if (!isCreditsSet) {
        this.$swal({
          title: this.$i18n.t('There are no Credits to distribute'),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
            confirmButtonText: this.$i18n.t('Okay'),
          },
          buttonsStyling: false,
        })

      } else {
        let userTable = this.$i18n.t('The following employees receive the respective credits!')

        userTable += '<br/><hr/>'

        this.items.forEach(function (user) {
          if (user.credit_count > 0) {
            userTable += user.name + ' : ' + user.credit_count + ' ' + 'Credits' + '<br/><hr/>' + ('<br>')
          }
        })

        this.$swal({
          title: this.$i18n.t('Do you really want to distribute the credits?'),
          html: userTable,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Yes, distribute the credits'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.isConfirmed) {
                let url = 'client/credits/move'
                this.$http.post(url, {users: this.items})
                    .then(response => {
                      this.$swal({
                        title: this.$i18n.t('Credits successfully distributed'),
                        icon: 'success',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                      this.resetCredits()
                      this.getCreditAmount()
                      this.UPDATE_CREDITS()
                      this.$refs.employeesDistributeCreditsTable.refresh()

                    })
              }
            })
      }
    },

    formatName(value, key, item) {
      if (item.firstname == null && item.name == null) return item.email

      if (!item.firstname) return item.name

      return item.firstname + ' ' + item.name

    },

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.credit-input {
  width: 45%;
  height: 50%;
}

.card-congratulations {
  background: #ad8a60;
}

.card {
  color: aliceblue;
}

.award {
  color: white;
}

.circle {
  background-color: #026666;
}

.payout {
  background-color: #026666 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
